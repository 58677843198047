.summarization {
    width: 950px;
    padding: 5px;
    .card_title {
        margin: 20px;
        font-style: italic;
        font-size: 26px;
        font-family: helvetica;
        color: @summ-title-color;
        float: left;
    }
    .new-wizard {
        float: right;
    }

    .links {
        width: 250px;
        border: 1px solid @history-border;
        .border-radius(5px);
        .shadowpanel();
        position: absolute;
        top: 60px;
        right: 8px;
        z-index: 1000;

        .button {
            font-family: verdana;
            display: block;
            border-top: 1px solid @history-option-border;
            background-image: url("../images/menu-polozka-tlacitko.png");
            background-position: top right;
            background-repeat: repeat-y;

            .item {
                background-image: url("../images/menu-polozka-sipka.png");
                background-position: center right;
                background-repeat: no-repeat;

                .caption {
                    background-color: @history-background;
                    background-image: url("../images/menu-polozka-pozadi.png");
                    background-repeat: repeat-x;
                    padding: 10px 0 10px 10px;
                    display: inline-block;
                    width: 212px;

                    .name-card {
                        font-size: 12px;
                        color: @history-name-card-color;
                    }

                    .option {
                        font-size: 12px;
                        color: @history-option-color;
                        padding: 0 10px;
                        line-height: 15px;

                        div {
                            clear: right;
                            color: @history-option-color;
                        }

                        div.temp {
                            clear: right;
                            color: @history-option-color-temp;
                        }

                        .value {
                            float: right;
                            max-width: 43%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    &:hover, &.hover {
                        background-image: url("../images/menu-polozka-pozadi-over.png");
                        background-repeat: repeat-x;
                    }
                }

                &:hover, &.hover {
                    background-image: url("../images/menu-polozka-sipka-over.png");
                    background-repeat: no-repeat;
                }
            }

            &:hover, &.hover {
                background-image: url("../images/menu-polozka-tlacitko-over.png");
                background-repeat: repeat-y;
                border-top: 1px solid @history-option-border-hover;
            }
        }

        :first-child {
            border: 0;
            &:hover, &.hover {
                border: 0;
            }
        }
    }

    .content {
        margin: 0;
        padding: 0;

        .description-container {
            margin-top: 20px;

            .information {
                width: 926px;
                padding: 10px;
            }
        }

        .note {
            textarea {
                margin: 0 0 10px 0;
                padding: 0;
                width: 946px;
            }
        }
        .order, .demand  {
            margin: 0;
        }
        .order {
            margin-left: 3px;
        }

        table {
            width: 100%;
            font-family: verdana;
            color: @summ-table-text-color;
            border-collapse: separate;
            border-spacing: 0px 5px;

            thead {
                font-size: 11px;
                th {
                    font-weight: normal;
                }
                .view {
                    padding-left: 20px;
                    width: 120px;
                }
				.name-wizard {
						padding-left: 10px;
					}
                .view, .name-product, .name-wizard {
                    text-align: left;
                }
                .price-without-tax, .price-with-tax {
                    text-align: right;
                }
                .currency {
                    text-align: center;
                    width: 80px;
                }
                .list, .copy, .delete, .send-demand {
                    text-align: center;
                    width: 60px;
                }
            }
            tbody {
                font-size: 12px;
                tr {
                    height: 60px;
                    //line-height: 120px;
                    .trans(@summ-table-tr-background,0.5);
                    &:hover, &.hover {
                        .trans(@summ-table-tr-highlight-background,0.5);
                        .highlight(@summ-table-tr-highlight);
                        cursor: pointer;
                    }
                }
                td {
                    border-top: 1px solid @summ-table-tr-border;
                    border-bottom: 1px solid @summ-table-tr-border;
                    &.view {
                        .image {
                            width: 100px;
                            height: 75px;
                            border: 1px solid @summ-table-img-border;
                            margin: 20px;
                        }
                    }
					&.name-wizard {
						padding-left: 10px;
					}
                    &.price-without-tax, &.price-with-tax {
                        text-align: right;
                    }
                    &.currency {
                        text-align: center;
                    }
                    &.list > div, &.copy > div, &.delete > div, &.send-demand > div {
                        border-left: solid 1px @summ-table-td-border-left-second;
                        position: relative;
                        height: 50px;
                        &:hover, &.hover {
                            box-shadow: none;
                        }
                    }
                    &.list > div:before, &.copy > div:before, &.delete > div:before, &.send-demand > div:before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: -2px;
                        border-left: solid 1px @summ-table-td-border-left-first;
                        min-height: 100%;
                    }
                    div {
                        .list-button, .copy-button, .delete-button, .send-demand-button {
                            border: 0;
                            background-color: transparent;
                            box-shadow: none;
                            position: relative;
                            left: 10px;
                            top: 5px;
                            &:hover, &.hover {
                                border: 0;
                                background-color: transparent;
                                box-shadow: none;
                            }
                        }
                        .list-button {
                            background-image: url("../images/ikony_40x40.png");
                            .icon(40, 8, 1);
                            &:hover, &.hover {
                                background-image: url("../images/ikony_40x40_active.png");
                                .icon(40, 8, 1);
                            }
                            &:disabled, &.disabled {
                                background-image: url("../images/ikony_40x40_disabled.png");
                                .icon(40, 8, 1);
                            }
                            .history {
                                position: absolute;
                                left: -30px;
                                top: 45px;
                            }
                        }
                        .copy-button {
                            background-image: url("../images/ikony_40x40.png");
                            .icon(40, 7, 1);
                            &:hover, &.hover {
                                background-image: url("../images/ikony_40x40_active.png");
                                .icon(40, 7, 1);
                            }
                            &:disabled, &.disabled {
                                background-image: url("../images/ikony_40x40_disabled.png");
                                .icon(40, 7, 1);
                            }
                        }
                        .delete-button {
                            background-image: url("../images/ikony_40x40.png");
                            .icon(40, 6, 1);
                            &:hover, &.hover {
                                background-image: url("../images/ikony_40x40_active.png");
                                .icon(40, 6, 1);
                            }
                            &:disabled, &.disabled {
                                background-image: url("../images/ikony_40x40_disabled.png");
                                .icon(40, 6, 1);
                            }
                        }
                        .send-demand-button {
                            background-image: url("../images/ikony_40x40.png");
                            .icon(40, 13, 1);
                            &:hover, &.hover {
                                background-image: url("../images/ikony_40x40_active.png");
                                .icon(40, 13, 1);
                            }
                            &:disabled, &.disabled {
                                background-image: url("../images/ikony_40x40_disabled.png");
                                .icon(40, 13, 1);
                            }
                        }
                    }
                }
                td:first-child {
                    border-left: 1px solid @summ-table-tr-border;
                    .border-radius-left(5px);
                }
                td:last-child {
                    border-right: 1px solid @summ-table-tr-border;
                    .border-radius-right(5px);
                }
            }
        }
    }

    .card-help-btn {
            background: white;
            border: 1px solid #87B1C0;
            border-left: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 10px 5px;
            position: absolute;
            right: -53px;
            top: 125px;
            .shadow(0px, 0px, 12px, 8px, #DEF0FD, inset);
            cursor: pointer;
        }
}

.dialog-choose-variant {
    table {
        width: 100%;
        font-family: verdana;
        color: @summ-table-text-color;
        border-collapse: separate;
        border-spacing: 0px 5px;
        padding-right: 20px;

        thead {
            font-size: 11px;
            th {
                font-weight: normal;
            }
            .name-wizard {
                text-align: left;
            }
            .price-without-tax, .price-with-tax {
                text-align: right;
            }
            .currency {
                text-align: center;
                width: 80px;
            }
            .choose {
                text-align: center;
                width: 30px;
            }
        }
        tbody {
            font-size: 12px;
            tr {
                height: 30px;
                line-height: 30px;
                .trans(@summ-table-tr-background,0.5);
                &:hover, &.hover {
                    .trans(@summ-table-tr-highlight-background,0.5);
                    .highlight(@summ-table-tr-highlight);
                    cursor: pointer;
                }
                &.selected {
                    .trans(@summ-table-tr-highlight-background,0.5);
                }
            }
            td {
                border-top: 1px solid @summ-table-tr-border;
                border-bottom: 1px solid @summ-table-tr-border;
                &.price-without-tax, &.price-with-tax {
                    text-align: right;
                }
                &.currency {
                    text-align: center;
                }
                &.choose > div {
                    border-left: solid 1px @summ-table-td-border-left-second;
                    position: relative;
                    height: 20px;
                    &:hover, &.hover {
                        box-shadow: none;
                    }
                }
                &.choose > div:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: -2px;
                    border-left: solid 1px @summ-table-td-border-left-first;
                    min-height: 100%;
                }
            }
            td:first-child {
                border-left: 1px solid @summ-table-tr-border;
                .border-radius-left(5px);
                padding-left: 5px;
            }
            td:last-child {
                border-right: 1px solid @summ-table-tr-border;
                .border-radius-right(5px);
            }
        }
    }

    .delete_unchecked {
        position: absolute;
        right: 22px;
    }
}