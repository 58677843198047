/**
 * Konstantni polozky
 * @author Petr Fotijev
 */

/**
 * Klíčová slova: error, description, disabled, info, success
 */
@body-color: #000;
@body-background-color: #A4D4E8;
@body-background-gradient: url("../images/background-blue5-gradient.jpg");
@body-background-image: url("../images/background-blue5.jpg");

@settings-minheight: 350px;

@form-items-height: 18px;

@options-item-width: 186px;

@nprogress-color: #0089D7;

.form-items{
    .border-radius(5px);
    border: 1px solid @form-items-border-color;
    //background-image: url("../images/input_bg.png");
    background-repeat: repeat-x;
    font-family: Calibri, Calib, Arial, Helvetica, sans-serif;
    color: @form-text-color;
}

.form-items-disabled{
    //background-image: url("../images/input_bg_disabled.png");
}

.shadowpanel(){
    .shadow(5px, 8px, 4px, 2px, rgba(00, 00, 00, 0.14));
}

.aleft{text-align: left;}
.aright{text-align: right;}
.acenter{text-align: center;}
.fleft{float: left;}
.fright{float: right;}
.inline{display: inline-block;}
.centered {text-align: center;}
.bold {font-weight: bold;}
.iheight input {height: 20px;}
.underline {text-decoration: underline !important;}
.pointer { cursor: pointer; }
.disabled {cursor: default; text-decoration: none !important; color: @nav-item-border !important; pointer-events: none;}