@media print {
    .dataGrid {
        color: black;
        font-family: Calibri,Calib,Arial,Helvetica,sans-serif;
        th {
            color: black;
            background: none;
            border-bottom: 1px solid #AAAAAA;
        }
        td {
            color: black;
            white-space: normal;
        }
        
        border-bottom: 1px solid #AAAAAA;
    }
}