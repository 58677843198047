.nav-history {
    .history {
        width: 330px;
        border: 1px solid @history-border;
        .border-radius(5px);
        .shadowpanel();
        position: absolute;
        top: 90px;
        //left: 100px;
        z-index: 1000;

        .button {
            font-family: verdana;
            display: block;
            border-top: 1px solid @history-option-border;
            background-image: url("../images/menu-polozka-tlacitko.png");
            background-position: top right;
            background-repeat: repeat-y;
            cursor: pointer;

            .item {
                background-image: url("../images/menu-polozka-sipka.png");
                background-position: center right;
                background-repeat: no-repeat;

                .caption {
                    background-color: @history-background;
                    background-image: url("../images/menu-polozka-pozadi.png");
                    background-repeat: repeat-x;
                    padding: 10px 0 10px 10px;
                    display: inline-block;
                    width: 292px;

                    .name-card {
                        font-size: 12px;
                        color: @history-name-card-color;
                    }

                    .option {
                        font-size: 12px;
                        color: @history-option-color;
                        padding: 0 10px;
                        line-height: 15px;

                        div {
                            clear: right;
                            color: @history-option-color;
                        }

                        .value {
                            float: right;
                            max-width: 43%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

					.temp {
						.option {
							clear: right;
							color: @history-option-color-temp;
						}
					}

                    &:hover, &.hover {
                        background-image: url("../images/menu-polozka-pozadi-over.png");
                        background-repeat: repeat-x;
                    }
                }

                &:hover, &.hover {
                    background-image: url("../images/menu-polozka-sipka-over.png");
                    background-repeat: no-repeat;
                }
            }

            &:hover, &.hover {
                background-image: url("../images/menu-polozka-tlacitko-over.png");
                background-repeat: repeat-y;
                border-top: 1px solid @history-option-border-hover;
            }
        }

        :first-child {
            border: 0;
            &:hover, &.hover {
                border: 0;
            }
        }
    }
}