
.calculation-border{
    border: 2px solid black;
    height: 100%;
    padding: 0;
    margin: 0;
}

.calculation-border .columns .column-two{
    display: inline;
    float: left;
    width:466px;
    padding: 0px 0 0 10px;
}
.columns{
    //border-bottom: 1px solid black;
}

.calculation-name{
    padding: 5px 0 5px 10px;
    font-size: 16px;
    font-weight: bold;
}

.title-text{
    padding-left: 70px;
}

.calculation-print {
    font-family: Calibri,Calib,Arial,Helvetica,sans-serif;
    font-size: 13px;

    .dataGrid {
        tr {
            line-height: 16px;
            font-size: 14px;
        }
    }
}

.calculation-print pre {
    font-family: Calibri,Calib,Arial,Helvetica,sans-serif;
    font-size: 13px;
}

.calculation-sumarization {
    border-top: 1px solid black;
    padding-top: 20px;
    padding-left: 375px;
    font-family: Calibri,Calib,Arial,Helvetica,sans-serif;
    font-size: 13px;

    .sumarization-table {
        width: 100%;
        td {
            padding-right: 0px;
        }
    }
    .price {
        width: 110px;
        text-align: right;
        padding: 0 5px;
    }
    .currency {
        width: 40px;
        padding: 0 5px;
    }
    .label {
        width: 190px;
    }
    .discount {
        width: 30px;
    }
    .discount_type {
        width: 30px;
    }
    .discount_text {
        width: 80px;
    }
}

.calculation-text{
    margin-top: 20px;
}

.calculation-notes{
    padding-left: 20px;
}