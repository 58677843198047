/**
 * Zakladni styl zatimni
 * @author Petr Fotijev
*/

@font-face {
    font-family: 'Calib';
    src: url('../css/Calibri.ttf') format('truetype');
}

body {
    font-family: Calibri,Calib, Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: @body-color;
    margin: 0px;
    padding: 0px;

    background: @body-background-image center top no-repeat, @body-background-gradient left top repeat-x;
    background-color: @body-background-color;
    //background-image: @body-background-image;
    //background-image: @body-div-background-image;
    //background-position: center 73px;
    //background-repeat: repeat-x;
    overflow-y: scroll;

    &.contract-info {
        background-color: #fff;
        background-image: none;
    }

    &.application-loading {
        cursor: progress !important;
    }
}

a:link {
    text-decoration: none;
    color: @a-link-color;
}
a:hover {
    text-decoration: none;
    color: @a-hover-color;
}
a:visited {
    text-decoration: none;
    color: @a-visited-color;
}
a img {
    border: none;
}

.margin-auto {
    margin: auto;
}

#top-strip {
    height: 73px;
    background-image: url("../images/pozadi_top.png");
    background-repeat: repeat-x;
}

#nav {
    width: 955px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px 0 25px;
    position: relative;
}
.nav-marker {
    float: left;
    background-image: url("../images/zalozka_menu_hlavicka.png");
    background-position: 0 0;
    height: 37px;
    width: 69px;
    position: relative;
    top: 2px;
    left: -14px;
    z-index: 10;
}
.nav-marker:hover {
    background-position: 69px 0;
    height: 37px;
    width: 69px;
    position: relative;
    top: 2px;
    left: -14px;
    cursor: pointer;
}
.nav-marker-menu {
    float: left;
    background-image: url("../images/zalozka_menu_hlavicka2.png");
    background-position: 69px 0;
    height: 37px;
    width: 69px;
    position: relative;
    top: 2px;
    left: -14px;
    z-index: 10;
}
.nav-marker-menu:hover {
    background-image: url("../images/zalozka_menu_hlavicka.png");
    background-position: 69px 0;
    height: 37px;
    width: 69px;
    position: relative;
    top: 2px;
    left: -14px;
    cursor: pointer;
}
#nav-marker-menu {
    position: relative;
    top: 10px;
    left: 20px;
    background-image: url("../images/ikony_bile_26x26.png");
    .icon(26,1,1);
}
#nav-marker-home {
    position: relative;
    top: 10px;
    left: 20px;
    background-image: url("../images/ikony_bile_26x26.png");
    .icon(26,2,1);
}

#nav-marker-cart {
    position: relative;
    top: 10px;
    left: 20px;
    background-image: url("../images/ikony_bile_26x26.png");
    .icon(26,3,1);
}

.nav-item {
    height: 40px;
    float: right;
    vertical-align: middle;
    position: relative;
    top: -30px;

    &.border {
        .border-radius(5px);
        border: 1px solid @nav-item-border;
        .trans(@sign-in-form-trans,0.7);
        margin: 0 2px;
    }
}
.nav-item-icon {
    float: left;
    width: 40px;
    height: 40px;
}
.nav-item-text {
    font-family: Verdana;
    font-size: 11px;
    color: @nav-item-text-color;
    margin: 0 15px 0 15px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;

    &.login, &.contact-us, &.contracts {
        font-size: 12px;
        color: @nav-item-text-login-color;
        font-weight: normal;
        &:hover {
            text-decoration: underline;
        }
    }
}
#nav-logout{
    margin-left: 0px;
}
#nav-help{
    margin-left: 0px;
}
#nav-is{
    margin-left: 0px;
}
#nav-currency{
    color: white;
    font-size: 14px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
}

#nav-flag{
    margin-right: 10px;
}

#nav-flag-text{
    color: @nav-flag-text-color;
    margin: 4px;
    text-align: center;
    background: white;
    min-width: 20px;
    .border-radius(10px);
    line-height: 20px;
}

#eventpanel {
    z-index: 100;
    position: absolute;
    max-width: 250px;
    top: -10px;
    left: 660px;
    border: 1px solid @eventpanel-border;
    color: @eventpanel-color;
    .border-radius(4px);
    .shadowpanel();
}
#eventpanel-name {
    .border-radius-top(3px);
    padding: 0 0 0 10px;
    font-size: 12px;
    line-height: 30px;
    font-weight: bold;
    //background-image: url("../images/eventpanel_name.png");
    //background-repeat: repeat-x;
    height: 30px;
}

#eventpanel-exit {
    margin: 1px 4px 0 0;
    float: right;

    &:hover{
        //background-image: url("../images/ikony_26x26.png");
        .icon(26,14,1);
    }
}

.eventpanel-item {
    padding: 0 20px 0 20px;
    font-size: 11px;
    line-height: 24px;
    //background-image: url("../images/eventpanel_item.png");
    //background-repeat: repeat-x;
    height: 24px;
}

.eventpanel-item:last-child{
    height: 23px;
    .border-radius-bottom(3px);
}

#logos {
    position: relative;
    top: -10px;
    width: 950px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 5px 0 5px;
}

.main {
    margin: 0 auto;
    width: 960px;
    position: relative;
    top: -17px;

    .card, .card-contact-us, .card-contracts, .card-contact-information, .card-forgotten-password, .card-registration, .card-sign-in, .card-summarization, .card-personal-information {
        .trans(@form-background,0.5);
        border: 1px solid @form-border;
        .border-radius(5px);
        .shadow(0px, 0px, 3px, 1px, white, inset);
        min-height: 450px;
        margin-bottom: 50px;
        position: relative;

        h2 {
            font-size: 26px;
            font-family: helvetica;
            color: @step-menu-title-color;
            font-style: italic;
            font-weight: normal;
            text-align: center;
            margin: 0 60px;
        }

        .loading {
            height: 280px;
            background-image: url("../images/button_loading.gif");
            background-position: center center;
            background-repeat: no-repeat;
        }

        &.close {
            padding-left: 20px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }

        &.home {
            div {
                font-size: 12px;
                font-family: verdana;
                color: @form-text-color;
                padding: 20px;
            }
        }

        &.contracts {
            .dataGrid {
                font-size: 12px;
            }
        }
    }
}

form {
    //.trans(@form-background,0.5);
    //border: 1px solid @form-border;
    //.border-radius(5px);
    //.shadow(0px, 0px, 3px, 1px, white, inset);

    div {
        font-size: 12px;
        font-family: verdana;
        color: @form-text-color;
    }

    input, select {
        height: 20px;
        margin: 0 0 4px 0;
        border: 1px solid @form-select-border;
        .shadow(0px, 0px, 3px, 1px, @form-select-shadow-inset, inset);

        &:hover {
            border: 1px solid @form-select-border-highlight;
            .shadow(0px, 0px, 3px, 1px, @form-select-shadow-outset);
        }
        &.required {
            .trans(@form-item-required-background,0.3);
        }
    }
}

.logo {
    margin: 0 auto 10px auto;
    width: 960px;
    height: 60px;
    img {
        height: 60px;
        float: right;
    }
}


/* Flash message */
.message-box {
    display: none;
    position: absolute;
    z-index: 100;
    left: 280px;
    top: -40px;
    line-height: 30px;
    //height: 30px;
    width: 500px;
    margin-top:5px;
    padding-left: 12px;
    border: 1px solid @message-box-border;
    background: @message-box-background url(../images/message_box_bg_blue.png) 50% 50% repeat-x;
    color: @message-box-color;
    font-size: 16px;
    .border-radius(6px);
    .shadowpanel();

    &.info {
        border: 1px solid @message-box-info-border;
        background: @message-box-info-background url(../images/message_box_bg_green.png) 50% 50% repeat-x;
        color: @message-box-info-color;

        .message-box-icon {
            background-image: url("../images/ikony_16x16.png");
            .icon(16,17,9);
        }
    }

    &.success {
        border: 1px solid @message-box-success-border;
        background: @message-box-success-background url(../images/message_box_bg_green.png) 50% 50% repeat-x;
        color: @message-box-success-color;

        .message-box-icon {
            background-image: url("../images/ikony_16x16.png");
            .icon(16,17,9);
        }
    }

    &.error, &.warning {
        border: 1px solid @message-box-error-border;
        background: @message-box-error-background url(../images/message_box_bg_red.png) 0% 0% repeat-x;
        color: @message-box-error-color;

        .message-box-icon {
            background-image: url("../images/ikony_16x16.png");
            .icon(16,15,9);
        }
    }

    .message-box-icon {
        float: left;
        margin: 7px 12px 0 0;
        background-image: url("../images/ikony_16x16.png");
        .icon(16,16,9);
    }

    .message-box-content {
        //text-overflow: ellipsis;
        //overflow: hidden;
        //white-space: nowrap;
    }
}

/*vypisy a formulare*/

/*plovouci sipka*/
.arrowmess{
    z-index: 2000;
    position: absolute;
    display: none;

    .arrowmess-arrow{
        z-index: 500;
        background-image: url("../images/sipky_dialogy_12x18.png");
        position: absolute;
        top: 6px;
        left: 0px;
        .unicon(12,18,2,1);
    }

    .arrowmess-body{
        position: absolute;
        border: 1px solid @arrowmess-body-border;
        min-height: 22px;
        overflow: hidden;
        padding: 6px 10px 3px 10px;
        left: 8px;
        .trans(@arrowmess-body-trans,0.9);
        .border-radius(3px);
    }

    &.error {
        .arrowmess-arrow{
            .unicon(12,18,1,1);
        }
        .arrowmess-body{
            border: 1px solid @error-arrowmess-body-border;
            .trans(@error-arrowmess-body-trans,0.9);
        }
    }

    &.description {
        .arrowmess-arrow{
            .unicon(12,18,3,1);
        }
        .arrowmess-body{
            border: 1px solid @description-arrowmess-body-border;
            .trans(@description-arrowmess-body-trans,0.9);
        }
    }
}

.error-highlight, .error-highlight:hover, .error-highlight:focus {
    .shadow(0px, 0px, 3px, 1px, @error-highlight) !important;
    .trans(@error-highlight, 0.6) !important;
}
.warning-highlight, .warning-highlight:hover, .warning-highlight:focus {
    .shadow(0px, 0px, 3px, 1px, @warning-highlight) !important;
}

label, div.label {
    line-height: @form-items-height+2;
}
.pair label, .pair div.label {
    padding-bottom: 0px;
}
input {
    padding: 0;
    height: @form-items-height;
    line-height: @form-items-height;
    .form-items;
}
select {
    height: @form-items-height+2;
    line-height: @form-items-height;
    .form-items;
}
input:disabled, select:disabled{
    .form-items-disabled;
}
textarea {
    .form-items;
    resize: vertical;
    min-height: 90px;
}
textarea:disabled{
    .form-items-disabled;
    background-color: white;
}

input[type=button]:hover,
input[type=submit]:hover,
input:focus,
select:focus,
textarea:focus {
    .highlight(@form-items-highlight);
}

input[type=button].disabled:hover,
input[type=button]:disabled:hover,
input[type=submit].disabled:hover,
input[type=submit]:disabled:hover,
input.disabled:focus,
input:disabled:focus,
select.disabled:focus,
select:disabled:focus,
textarea.disabled:focus,
textarea:disabled:focus {
    border: 1px solid #7e929e;
    .shadow(0px, 0px, 0px, 0px, @form-items-highlight);
}

.pair {
    display: inline;
    margin-bottom: 2px;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.more-content-container{
    padding: 5px;
}