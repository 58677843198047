.content {
    padding-left: 66px;
    min-height: 360px;

	.at-row-5 {
		.item-img {
			height: auto !important;
			width: 140px !important;
			.option-img {
				height: 105px !important;
				width: 140px !important;
			}
			.option-text {
				width: 140px !important;
			}
		}
	}

    .item-img {
        border: 1px solid @form-border;
        .border-radius(5px);
        padding: 10px;
        .trans(@form-background,0.7);

        &:hover, &.selected {
            .highlight(@content-items-hover);
            .trans(@content-items-selected-background,0.7);
            position: relative;
            z-index: 1;
            border: 1px solid @content-items-hover;
        }
        .option-img{
            &.horizontal{
                width:160px;
                height:120px;
                overflow: hidden;
            }
            &.vertical{
                float:left;
                //width:120px;
                height:90px;
                margin-right: 20px;
            }
        }
        .option-text{
            &.horizontal{
                width:160px;
                padding-top:5px;
            }
            &.vertical{

            }
        }

        .option {
            display: none;
        }
    }

	.description-container {
		.information {
			width: 816px;
		}
	}

    .information, .box {
        .trans(@form-background,0.4);
        border: 1px solid @form-border;
        .border-radius(5px);
        margin-bottom: 20px;
    }

    .information img {
        float: left;
        max-width: none;
    }

    .menu {
        margin-bottom: 20px;
    }

	.options {
		.horizontal {
			.at-row-2, .at-row-3, .at-row-4 {
				margin: 0 auto;
			}

			.at-row-2 {
				width: @options-item-width*2;
			}

			.at-row-3 {
				width: @options-item-width*3;
			}

			.at-row-4 {
				width: @options-item-width*4;
			}
		}
		&.vertical {
			.control {
				.description {
					width: 624px;
				}
			}
		}
	}

	.parameters {
		&.horizontal {
			div {
				label {
					text-align: left;
				}
			}
		}

		&.vertical {
			.control {
				.description {
					width: 486px;
				}
			}
		}
	}

    .groups {
        .control {
            .description {
                width: 386px;
            }
        }
    }

    .horizontal {
        width: 840px;
        //margin-bottom: 20px;

        .menu {
            text-align: center;
            h2 {
                text-align: left;
                margin-bottom: 0;
            }

            .top_space {
                height: 18px;
            }

            .item-img {
                width: 160px;
                height: 160px;
                float: left;
                line-height: 24px;
                margin: 2px;

                img {
                    display: block;
                    margin-bottom: 10px;
                    width: 100%;
                }
            }
        }

        .information {
            margin-top: 20px;
            h3 {
                font-size: 20px;
                font-style: italic;
                font-family: helvetica;
                padding: 0 20px
            }
            div {
                padding: 0 20px 10px 20px;
            }
        }


        .parameter-item{
            &.box {
                &.radiolist {
                    float: left;
                    margin: 0 0 -1px 0;
                    img {
                        float: left;
                        height: 45px;
                        width: 60px;
                        margin: 10px;
                    }

                    label {
                        padding: 10px;
                        width: 188px;
                    }

                    &.img {
                        label {
                            width: 118px;
                            padding-left: 0px;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:hover, &.selected {
                        .highlight(@content-items-hover);
                        .trans(@content-list-hover-background,0.7);
                        position: relative;
                        z-index: 1;
                        border: 1px solid @content-items-hover;
                    }
                }
            }

            .yes_no {
                margin-bottom: 7px !important;
            }

            .yes, .no {
                width: 100px;
                cursor: pointer;
                text-align: center;
                line-height: 20px;

                &.box {
                    margin-bottom: 0px;
                }

                &.hover, &.selected {
                    .highlight(@content-items-hover);
                    .trans(@content-list-hover-background,0.7);
                    border: 1px solid @content-items-hover;
                }
            }

            .grid_9 {
                .yes, .no {
                    width: 81px;
                }
            }

            .grid_7 {
                .yes, .no {
                    width: 61px;
                }
            }

            .grid_3 {
                .yes, .no {
                    width: 21px;
                }
            }

            .yes {
                float: left;
            }

            .no {
                float: right;
            }
        }
    }

    .vertical {
        .menu > .control > .list, .item {
            border: 1px solid @form-border;
            .border-radius(5px);
            .trans(@form-background,0.7);
            margin-bottom: -1px;
            cursor: pointer;
        }

        .menu > .control > .list > .add, .menu > .control > .list > .remove {
            margin: 10px 10px 0 10px !important;
        }

        .menu {
            width: 100%;
            float: left;
            h2 {
                text-align: left;
                margin-bottom: 0;
            }

            .top_space {
                height: 18px;
            }

            .item-img {
                margin-bottom: -1px;
                cursor: pointer;

                img {
                    width: 120px;
                    height: 90px;
                    margin-right: 10px;
                }
            }

            .item {
                padding: 10px 0 10px 46px;
                &:hover {
                    .highlight(@content-items-hover);
                    .trans(@content-items-selected-background,0.7);
                    position: relative;
                    z-index: 1;
                }
            }

            .item, .list-item {
                //line-height: 24px;
                height: 24px;
                position: relative;
                clear: both;
            }

            .list {

                width: 430px;
                float: left;

                .caption {
                    margin: 10px;
                    line-height: 24px;
                    margin-left: 35px;
                }

                .add, .remove {
                    border: 1px solid @form-border;
                    .border-radius(5px);
                    float: left;
                    margin: 0 10px;
                }

                &.hover {
                    .highlight(@content-items-hover);
                    .trans(@content-list-hover-background,0.7);
                    position: relative;
                    z-index: 1;
                }

                .list-item {
                    &:hover {
                        .trans(@content-list-item-background,0.3);
                    }

                    label {
                        line-height: 24px;
                    }
                }
            }

            .list > .list-item, .list > .list-items > .list-item {
                padding: 5px 5px 5px 70px;
            }

            input {
                height: 20px;
                &.hover, &:hover {
                    border: 1px solid @form-input-border-highlight;
                    .shadow(0px, 0px, 3px, 1px, @form-input-shadow-outset);
                }
            }

            .item > input, .list-item > input {
                position: absolute;
                right: 40px;
            }

            label {
                margin: 0px;
                cursor: pointer;
            }
        }

        .information {
            float: left;
            h3 {
                font-size: 20px;
                font-style: italic;
                font-family: helvetica;
                padding: 0 20px
            }
            div {
                padding: 0 20px 10px 20px;
				//height: 20px;
            }
            &.description {
                padding: 5px;
                margin-bottom: -1px;
            }
        }

        .options_container{
            margin-bottom: 2px;
            .options {
                & > div:last-child {
                    margin-bottom: 7px !important;
                }
            }
        }
        .parameter-item{
            &.box {
                &.radiolist {
                    float: left;
                    margin: 0 0 -1px 0;
                    img {
                        float: left;
                        height: 45px;
                        width: 60px;
                        margin: 10px;
                    }

                    label {
                        padding: 10px;
                        width: 188px;
                    }

                    &.img {
                        label {
                            width: 118px;
                            padding-left: 0px;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:hover, &.selected {
                        .highlight(@content-items-hover);
                        .trans(@content-list-hover-background,0.7);
                        position: relative;
                        z-index: 1;
                        border: 1px solid @content-items-hover;
                    }
                }
            }

            .yes_no {
                margin-bottom: 7px !important;
            }

            .yes, .no {
                width: 100px;
                cursor: pointer;
                text-align: center;
                line-height: 20px;

                &.box {
                    margin-bottom: 0px;
                }

                &.hover, &.selected {
                    .highlight(@content-items-hover);
                    .trans(@content-list-hover-background,0.7);
                    border: 1px solid @content-items-hover;
                }
            }

            .yes {
                float: left;
            }

            .no {
                float: right;
            }
        }
    }

    .recapitulation {
        width: 828px;

        .description-container {
            .information {
                width: 786px;
            }
        }

        .information {
            margin: 0 0 -1px 0;
            padding: 10px 20px 20px;

            h3 {
                font-weight: normal;
                font-size: 14px;
                margin: 0;
                display: inline;
            }

            .info-image{
                float: left;
                width: 200px;
                height: 150px;
                border: 1px solid @img-border;
                margin-right: 20px;

                img {
                    width: 100%;
                }
            }

            &.item {
                &.selected {
                    //.highlight(@content-items-hover);
                    .trans(@recapitulation-items-selected-background,0.7);
                    position: relative;
                    z-index: 1;
                    border: 1px solid @recapitulation-items-hover;
                }
            }

            .option {
                display: none;
            }

            .header, .details {
                float: right;
                text-align: right;

                .quantity, .item_unit, .price_unit, .price_without_dph {
                    float: left;
                    height: 18px;
                }

                .quantity {
                    width: 70px;
                }
                .item_unit {
                    width: 30px;
                }
                .price_unit, .price_without_dph {
                    width: 120px;
                }
            }
        }

        .sum_price {
            padding: 20px;
            text-align: right;
        }

        .items {
            margin-bottom: 20px;
            padding: 30px 20px 5px 20px;

            .caption, .odd, .even {
                line-height: 22px;
                height: 22px;
            }

            .odd {
                .trans(@odd, 0.9);
            }

            .even {
                .trans(@even, 0.6);
            }

            .submit {
                margin-top: 10px;
            }
        }

        .card_description {
            padding: 0 20px 20px;
            text-align: justify;
        }

        .loading {
            height: 200px;
            background-image: url("../images/button_loading.gif");
            background-position: center center;
            background-repeat: no-repeat;
            //background: #FAFCF0 url("../images/button_loading.gif") no-repeat center;
        }

        .progress {
            font-size: 18px;
            text-align: center;
            margin-top: 20px;
        }
    }

    .description-container {
        .btn-more {
                cursor: pointer;
                .more {
                    font-size: 0px;
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    //.trans(@form-background,0.9);
                    //padding: 1px 8px 1px 8px;
                    margin: 3px;
                    text-align: center;
                    color: #056EC7;
                    font-weight: bold;
                    width: 31px;
                    height: 31px;
                    background-image: url("../images/ikony_40x40.png");
                    border: 0;
                    background-position: -404px -4px;
                    &.hover, &:hover {
                        .highlight(@content-items-hover);
                        //.trans(@content-items-selected-background,0.9);
                        z-index: 1;
                    }

            }
        }
        .single {
            margin-left: 20px;
        }
    }
}