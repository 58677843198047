
// podrobne vyhodnoceni cilu

.resultsDebugLink {
    margin-bottom: 50px;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.resultsDebug {
    display: none;

    h3 {
        margin-bottom: 5px;
    }

    table {
        //width: 100%;

        td, th {
            text-align: left;
            padding-left: 10px;
            vertical-align: top;
        }

        td:first-child {
            padding-left: 32px;
            width: 122px;
        }
    }

    .parameters {
        margin-bottom: 50px;
    }

    .targets {
        margin-bottom: 42px;

        .icon-20-checkbox {
            float: left;
            margin-right: 10px;
        }

        .subTargets {
            margin-left: 32px;
        }

        .assign {
            margin-left: 10px;
            font-weight: normal;
            opacity: 0.4;
        }

        .small {
            font-size: 75%;
        }

        .lighter {
            opacity: 0.4;
        }

        .warning {
            opacity: 0.4;
            color: red;
        }

    }

}