// Styly v PDF
@media print {

    body {
        background: none;
        overflow-y: scroll;

        .header {
            line-height: 15px;
            font-size: 15px;
            margin: 0;
            padding-bottom: 5px;
            border-bottom: 1px solid #000000;

            h3 {
                margin: 0px;
            }

            .title {
                text-align: right;
            }
        }

        .footer {
            font-size: 15px;
            border-top: 1px solid #000000;

            .footer-pages {
                display: block;
            }

        }

        #main {
            top: 0;
        }

        td, th {
            font-size: 12px;
        }

        .option-group {
            border: 0px;
            border-bottom: 1px solid #CCCCCC;
            background: none;
            padding-bottom: 0px;

            h3 {
                color: #999999;
                padding: 3px 0 3px 0;
            }
        }

        div.label {
            padding-right: 8px;
            padding-left: 0px;
            line-height: @form-items-height+2px;
        }

        div.input {
            padding-left: 2px;
            padding-right: 4px;
            line-height: @form-items-height+2px;
        }

        div.aright {
            text-align: right;
        }

        .container(48, 20px);

        .container(@cols, @colWidth: 20px) {
            .container_@{cols} {
                margin-left: auto;
                margin-right: auto;
                width: @colWidth * @cols;
                .grid(@cols, @colWidth);
            }
        }

        .grid(@i, @colWidth: 20px) when (@i > 0) {
            .grid_@{i} {
                margin-right: 0px; // @todo osetrit right margin v PDF u float left
                width: 7px + ((@i - 1) * @colWidth);
            }
            .grid(@i - 1);
        }
    }




}
.print-sumarization{
        border: 1px dotted black;
        margin-top: 10px;
    }
    .sumarization-right{
        position: absolute;
        right: 10px;
    }

    .calculation-header{
        float: right;
        color: black;
        font-size: 20px;
        font-weight: bold;
        text-align: right;
    }

    .columns{
        width: 957px;
    }

    .columns .column-one{
    	display: inline;
        float: left;
        width:307px;
        padding: 0px 0 0 10px;
    }

    .columns .column-two{
    	display: inline;
        float: left;
        width:468px;
        padding: 0px 0 0 10px;
    }

    .columns .column-three{
        display: inline;
        float: left;
        width: 307px;
        padding: 0px 0 0 10px;
    }
