#currencypanel{
    z-index: 100;
    position: absolute;
    width: 120px;
    top: 50px;
    right: 20px;
    color: @currencypanel-color;
}

#currencypanel > .body {
    .trans(@currencypanel-item-background,0.7);
    border: 1px solid @currencypanel-border;
    .border-radius(4px);
    position: absolute;
    width: 120px;
    left: 50%;
    .shadowpanel();
}

#currencypanel > .body:before, #currencypanel > .body:after {
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -10px;
}

#currencypanel > .body:before {
    border-bottom: 8px solid @currencypanel-border;
    margin-top: -2px;
}

#currencypanel > .body:after{
    border-bottom: 8px solid #fff;
    margin-top: -2px;
    z-index: 100;
}

.currencypanel-item{
    padding: 0px 10px;
    font-size: 12px;
    line-height: 24px;
    height: 24px;
}
.currencypanel-lastitem{
    padding: 0px 10px;
    font-size: 12px;
    line-height: 24px;
    height: 23px;
}

.currencypanel-item:hover{
    .shadow(0px, 0px, 5px, 1px, @currencypanel-item-hover, inset);
}
.currencypanel-lastitem:hover{
    .shadow(0px, 0px, 5px, 1px, @currencypanel-item-hover, inset);
}

.currencypanel-name{
    float: left;
}