.personal-information {
    .content {
        width: 838px;

        .border {
            .trans(@form-background,0.4);
            border: 1px solid @form-border;
            .border-radius(5px);
            margin-bottom: 10px;
            padding: 20px;
        }
        
        .personal-information-form {
            .address {
                h3 {
                    color: #056EC7;
                    font-family: helvetica;
                    font-size: 16px;
                    margin: 0 0 10px;
                }
            }
            
            label {
                margin-left: 0px;
                text-align: right;
            }
        }
    }
    .step_menu {
        .label_step {
            margin: 5px 0;
            &:disabled, &.disabled {
                color: @step-menu-color-disabled;
            }
            &.middle  {
                line-height: 56px;
                margin: 4px 0;
            }
        }
    }
    
}