.sign-in-form {
    //background-color: white;
    .trans(@sign-in-form-trans,0.7);
    background-image: url("../images/prihlaseni-pozadi-prechod.png");
    background-repeat: repeat-x;
    width: 240px;
    z-index: 1000;

    position: absolute;
    top: 65px;
    right: 20px;
    border: 1px solid @sign-in-form-border;
    .border-radius(5px);
    .shadowpanel();
    
    .container(12, 20px);

    .exit {
        width: 24px;
        height: 24px;
        border: 1px solid @sign-in-form-exit-border;
        .border-radius(5px);

        &:hover {
            .highlight(@form-items-highlight);
        }
    }

    .submit, .exit {
        margin: 5px;
    }

    .text {
        font-weight: bold;
        color: @sign-in-form-text-color;
    }

    .title {
        line-height: 24px;
        padding: 5px;
    }

    .change-pass {
        line-height: 43px;
        padding-left: 5px;
        &:hover {
            text-decoration: underline;
        }
    }

    .bottom {
        height: 43px;
        background-image: url("../images/prihlaseni-pozadi-prechod-spodni.png");
        background-repeat: repeat-x;
        margin: 5px 0px 0px 0px;
        .border-radius-bottom(5px);
    }
}

.sign-in {
    .step_menu {
        .button {
            .label_step {
                margin: 5px 0;
            }
        }
    }
    
    .submit {
        margin: 5px;
    }

    .text {
        font-weight: bold;
        color: @sign-in-form-text-color;
    }

    .change-pass {
        line-height: 43px;
        padding-left: 5px;
        &:hover {
            text-decoration: underline;
        }
    }
}