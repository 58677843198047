#langpanel{
    z-index: 100;
    position: absolute;
    width: 110px;
    top: 50px;
    right: 20px;
    color: @langpanel-color;
}

#langpanel > .body {
    .trans(@langpanel-item-background,0.7);
    border: 1px solid @langpanel-border;
    .border-radius(4px);
    position: absolute;
    width: 110px;
    left: 50%;
    .shadowpanel();
}

#langpanel > .body:before, #langpanel > .body:after {
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -10px;
}

#langpanel > .body:before {
    border-bottom: 8px solid @langpanel-border;
    margin-top: -2px;
}

#langpanel > .body:after{
    border-bottom: 8px solid #fff;
    margin-top: -2px;
    z-index: 100;
}

.langpanel-item{
    padding: 0px 0px 0px 0;
    font-size: 12px;
    line-height: 24px;
    height: 24px;
}
.langpanel-lastitem{
    padding: 0px 0px 0px 0;
    font-size: 12px;
    line-height: 24px;
    height: 23px;
}

.langpanel-item:hover{
    .shadow(0px, 0px, 5px, 1px, @langpanel-item-hover, inset);
}
.langpanel-lastitem:hover{
    .shadow(0px, 0px, 5px, 1px, @langpanel-item-hover, inset);
}
.langpanel-icon{
    float: left;
    margin: 4px 10px 0 10px;
}
.langpanel-name{
    float: left;
}