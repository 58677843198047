.contracts {
    .step_menu {
        .label_step {
            margin: 5px 0;
            &:disabled, &.disabled {
                color: @step-menu-color-disabled;
            }
            &.middle  {
                line-height: 56px;
                margin: 4px 0;
            }
        }
    }
	
	.submit {
		height: 18px;
		line-height: 18px;
		margin-left: 10px;
		background-image: url("../images/form_submit_bg.png");
		background-position: left top;
		background-repeat: repeat-x;
		border: 1px solid #0f85d6;
		.border-radius(3px);

		&:hover {
			.highlight(@form-items-highlight);
		}

		&.disabled {
			background-image: url("../images/form_submit_bg_disabled.png");
			border: 1px solid #7e929e;
		}
	}
}