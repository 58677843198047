.registration {
    .content {
        width: 838px;

        .border {
            .trans(@form-background,0.4);
            border: 1px solid @form-border;
            .border-radius(5px);
            margin-bottom: 10px;
            padding: 20px;
        }

        .registration-form {
            label {
                margin-left: 0px;
                text-align: right;
            }
            .antispam {
                height: 50px;
                width: 600px;
                position: relative;
                float: left;
                img {
                    border: 1px solid @form-input-border;
                    .border-radius(5px);
                    margin: 0 5px;
                }
                label {
                    margin-top: 10px;
                }
                .captcha {
                    height: 50px;
                    input {
                        top: 40px;
                        position: absolute;
                    }
                    .refresh {
                        background-color: white;
                        border: 1px solid #87b1c0;
                        border-radius: 3px;
                        position: absolute;
                        
                        &:hover {
                            border: 1px solid #467297;
                            box-shadow: 0 0 3px 1px #a6d8f3;
                        }
                    }
                }
                .code {
                    position: absolute;
                    left: 310px;
                    top: 20px;
                }
            }
            .send {
                margin-top: 2px;
                width: 130px;
            }
        }
    }
    .step_menu {
        .label_step {
            margin: 5px 0;
            &:disabled, &.disabled {
                color: @step-menu-color-disabled;
            }
            &.middle  {
                line-height: 56px;
                margin: 4px 0;
            }
        }
    }
    
}