.forgotten-password {
    .content {
        width: 838px;

        .border {
            .trans(@form-background,0.4);
            border: 1px solid @form-border;
            .border-radius(5px);
            margin-bottom: 10px;
            padding: 20px;
        }
    }
    .step_menu {
        .label_step {
            margin: 5px 0;
            &:disabled, &.disabled {
                color: @step-menu-color-disabled;
            }
            &.middle  {
                line-height: 56px;
                margin: 4px 0;
            }
        }
    }
}