
// nastylovani jednotlivych sloupecku
.dataGrid {

    .datagrid-column-label {
        text-align: right;
    }

    .datagrid-column-number {
        text-align: right;
    }

    .datagrid-column-email {
        text-align: center;
    }
    
    .datagrid-column-center {
        text-align: center;
    }
    
    .datagrid-column-right {
        text-align: right;
    }
    
    .datagrid-column-left {
        text-align: left;
    }

    .valign-top{
        vertical-align: top;
    }

    .datagrid-column-img {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .datagrid-column-red {
        color: red;
    }
    
    .datagrid-tool-submit {
        .submit {
            height: 18px;
            line-height: 18px;
            margin-left: 0px;
            background-image: url("../images/form_submit_bg.png");
            background-position: left top;
            background-repeat: repeat-x;
            border: 1px solid #0f85d6;
            .border-radius(3px);
            text-align: center;
    
            &:hover {
                .highlight(@form-items-highlight);
            }
            
            &:disabled, &.disabled  {
                border: 1px solid #7e929e;
                background-image: url("../images/form_submit_bg_disabled.png");
            }
        }
    }

}



// obecne nastylovani datagridu
.dataGrid {
    border-collapse: collapse;
    border: none;
    font-size: 14px;
    table-layout: fixed;

    td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    td.notruncated {
        white-space: normal;
    }
    
    &.disabled {
        th {
            color: #6D6D6D !important; 
            background-image: url("../images/grid_th_bg_disabled.png");
            a {
                color: #6D6D6D !important;
            }
        }
        td {color: #6D6D6D !important;}
        .dataGrid-body-row-odd:hover {.trans(#e8e8e8,0.9);}
        .dataGrid-body-row-even:hover {.trans(#ffffff,0.9);}
    }

    th {
        height: 30px;
        color: #00aeff;
        border: none;
        text-align: left;
        background-image: url("../images/grid_th_bg.png");
        background-repeat: repeat-x;
        padding: 0 5px;
        a {
            color: #00aeff;
            border: none;
            &:hover {
                text-decoration: underline;
            }
        }
        .ico-arrow {
            margin-top: 4px; 
            margin-left: 3px;
        }
    }

    .dataGrid-buttons-header a {
        float: right;
        padding-left: 5px;
    }

    td {
        color: #505050;
        border: none;
        padding: 0 5px;
    }

    tr {
        height: 24px;
        line-height: 24px;
    }

    .dataGrid-body-row-odd {
        .trans(#e8e8e8,0.9);
        &:hover {
            .trans(#bbdff8,0.9);
        }
    }

    .dataGrid-body-row-even {
        .trans(#ffffff,0.9);
        &:hover {
            .trans(#bbdff8,0.9);
        }
    }

    .dataGrid-body-no-rows {
        .trans(#e8e8e8,0.9);
        td {
            text-align: center;
            opacity: 0.6;
        }
    }

    .selected {
        .trans(#54bdf7,0.9);
    }

    .dataGrid-body-button {
        margin-left: 4px;
        float: left;
        &.hidden{
            display: none;
        }
    }

    .dataGrid-buttons-td {
        //vertical-align: top;
        padding: 0 0 0 5px;
    }

    .datagrid-sortable-placeholder {
        border: 2px dashed #CCCCCC;
        line-height: 0px;
    }
}
