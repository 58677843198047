.contact-us {
    .content {
        width: 838px;
        
        p {
            margin: 0;
        }

        .border {
            .trans(@form-background,0.4);
            border: 1px solid @form-border;
            .border-radius(5px);
            margin-bottom: 10px;
            padding: 20px;
        }

        .guide, .quick-question, .contacts {
            h3 {
                color: @contact-us-h3-color;
                font-family: verdana;
                font-weight: normal;
                font-size: 20px;
                margin: 0 0 5px;
            }
        }

        .quick-question {
            label {
                margin-left: 0px;
                text-align: right;
            }
            /*textarea {
                margin-right: 0;
                padding: 5px;
                width: 644px;
                margin-bottom: 7px;
            }*/
            .antispam {
                height: 50px;
                width: 600px;
                position: relative;
                float: left;
                img {
                    border: 1px solid @form-input-border;
                    margin: 0 5px;
                    .border-radius(5px);
                }
                label {
                    margin-top: 10px;
                }
                .captcha {
                    height: 50px;
                    input {
                        top: 40px;
                        position: absolute;
                    }
                }
                .code {
                    position: absolute;
                    left: 310px;
                    top: 20px;
                }
            }
            .send {
                margin-top: 2px;
                width: 130px;
            }
        }
        .contacts {
            .phone-number {
                color: @contact-us-h3-color;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .step_menu {
        .label_step {
            margin: 5px 0;
            &:disabled, &.disabled {
                color: @step-menu-color-disabled;
            }
            &.middle  {
                line-height: 56px;
                margin: 4px 0;
            }
        }
    }
}