/**
 * Soubor s mixiny používanými v celé aplikaci
 * @author Jan Preuss
 */


// Pozicovani pozadi prvku
.background-origin (@origin: padding-box) {
    -webkit-background-origin: @origin;
    -moz-background-origin: @origin;
    background-origin: @origin;
}


// Zakulacené rohy
.border-radius (@radius: 5px) {
    .border-radius-top(@radius);
    .border-radius-bottom(@radius);
}

.border-radius-top(@radius: 5px) {
    .border-radius-top-left(@radius);
    .border-radius-top-right(@radius);
}

.border-radius-bottom(@radius: 5px) {
    .border-radius-bottom-left(@radius);
    .border-radius-bottom-right(@radius);
}

.border-radius-right(@radius: 5px) {
    .border-radius-top-right(@radius);
    .border-radius-bottom-right(@radius);
}

.border-radius-left(@radius: 5px) {
    .border-radius-top-left(@radius);
    .border-radius-bottom-left(@radius);
}

.border-radius-top-left(@radius: 5px) {
    -moz-border-radius-topleft: @radius;
    -webkit-border-top-left-radius: @radius;
    -khtml-border-top-left-radius: @radius;
    border-top-left-radius: @radius;
}

.border-radius-top-right(@radius: 5px) {
    -moz-border-radius-topright: @radius;
    -webkit-border-top-right-radius: @radius;
    -khtml-border-top-right-radius: @radius;
    border-top-right-radius: @radius;
}

.border-radius-bottom-left(@radius: 5px) {
    -moz-border-radius-bottomleft: @radius;
    -webkit-border-bottom-left-radius: @radius;
    -khtml-border-bottom-left-radius: @radius;
    border-bottom-left-radius: @radius;
}

.border-radius-bottom-right(@radius: 5px) {
    -moz-border-radius-bottomright: @radius;
    -webkit-border-bottom-right-radius: @radius;
    -khtml-border-bottom-right-radius: @radius;
    border-bottom-right-radius: @radius;
}


//
//  Nastaví neděděnou průhlednost prvku
//  @params color    - barva pozadí prvku (napr #00ff00)
//          alpha    - pruhlednost 0-1 (napr 0.5)
//          alphahex - alpha*255 prevedeno na hexadecimalni cislo
.trans(@color, @alpha) {
    background: @color;
    background: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
//    @hcolor: ~`"@{color}".substring(1)`;
//    filter: ~`"progid:DXImageTransform.Microsoft.gradient(startColorstr=#@{alphahex}@{hcolor}, endColorstr=#@{alphahex}@{hcolor})"`;
//    -ms-filter: ~'"progid:DXImageTransform.Microsoft.gradient(startColorstr=#@{alphahex}@{hcolor}, endColorstr=#@{alphahex}@{hcolor})"';
}

//nastaveni ikonky z mrizky do pozadi
.icon(@width, @x, @y) {
    width: ~"@{width}px";
    height: ~"@{width}px";
    @left: @width * (@x - 1);
    @top: @width * (@y - 1);
    background-position: ~"-@{left}px" ~"-@{top}px";
}
//nastaveni nepravidelne ikonky z mrizky do pozadi
.unicon(@width, @height, @x, @y){
    width: ~"@{width}px";
    height: ~"@{height}px";
    @left: @width * (@x - 1);
    @top: @height * (@y - 1);
    background-position: ~"-@{left}px" ~"-@{top}px";
}

//vytvori ikonky pro vsechny velikosti
//  @params name    -jmeno css bloku pro ktery se ikony vytvari
//          x       -poradi v X-ove souradnici
//          y       -poradi v Y-ove souradnici
.seticons(@name, @x, @y){
    .@{name}-16 {
        background-image: url("../images/ikony_16x16.png");
        .icon(16, @x, @y)
    }
    .@{name}-24 {
        background-image: url("../images/ikony_24x24.png");
        .icon(24, @x, @y)
    }
}

//nastavuje stin objektu
.shadow(@horizontal, @vertical, @blur, @spread, @color){
    -webkit-box-shadow:  @horizontal @vertical @blur @spread @color;
    box-shadow:  @horizontal @vertical @blur @spread @color;
}

//nastavuje stin objektu s umistenim vnitrni/vnejsi
.shadow(@horizontal, @vertical, @blur, @spread, @color, @place){
    -webkit-box-shadow:  @horizontal @vertical @blur @spread @color @place;
    box-shadow:  @horizontal @vertical @blur @spread @color @place;
}

//zvyrazneni objektu
.highlight(@color){
    .shadow(0px, 0px, 5px, 1px, @color);
}
