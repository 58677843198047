/**
 * Styly formuláře
 * @author Petr Fotijev
*/


.option-group{
    .trans(@option-group-trans,0.9);
    padding: 15px 0 15px 20px;
    background-image:  url("../images/option_group_bg.png");
    background-position: bottom left;
    background-repeat: repeat-x;
    width: 938px;
    border-right: 1px solid @option-group-border;
    border-left: 1px solid @option-group-border;

    .list-filter {
        width: 918px;
        background-image: url("../images/vypis_filtr.png");
        background-repeat: repeat-x;
        background-color: @option-group-list-filter-background;
        padding: 0 0 10px 0;
        color: white;
        position: relative;

        .form-submit {
            position:absolute;
            right: 50px;
            top: -10px;
            background-image: url("../images/tlacitko_filtr_32x90.png");
            background-position: left;
            width: 90px;
            height: 32px;
            color: @option-group-list-filter-form-submit-color;
            font-weight: bold;
            font-size: 13px;
            border-color: @option-group-list-filter-form-submit-border;
            padding: 0;
        }

        .list-filter-dot {
            margin: 10px 0 0 15px;
            float: left;
            background-image: url("../images/ikony_12x12.png");
            border: 1px solid @option-group-list-filter-dot-border;
            .border-radius(3px);
            .icon(12,1,1);
        }

        .list-filter-name {
            font-weight: bold;
            color: @option-group-list-filter-name-color;
            float: left;
            margin: 8px 0 0 12px;
        }

        .list-filter-switch {
            color: @option-group-list-filter-switch-color;
            float: left;
            margin: 8px 0 0 12px;
            a {
                color: @option-group-list-filter-switch-a-color;
                &.active, &:hover {
                    color: @option-group-list-filter-switch-a-hover-color;
                }
                span {
                    color: @option-group-list-filter-switch-a-span-color;
                }
            }
        }

        .list-filter-count {
            float: right;
            color: @option-group-list-filter-count-color;
            padding: 9px;
        }
    }

    .list-paging {
        width: 918px !important;
        color: @option-group-list-paging-color;
        height: 28px;
        line-height: 28px;
        background-image: url("../images/vypis_strankovani_bg.png");
        background-repeat: repeat-x;
        text-align: center;

        a {
            color: @option-group-list-paging-a-color;
            &:hover {
                color: @option-group-list-paging-a-hover-color;
                text-decoration: underline;
            }
        }

        .list-prev {
            margin-right: 25px;
        }

        .list-next {
            margin: 0 25px;
        }

        input {
            width: 43px;
            height: 14px;
        }

        input[type=submit] {
            width: 18px;
            height: 18px;
            background-image: url("../images/vypis_strankovani_tlacitko.png");
            background-repeat: no-repeat;
            border: none;
        }

        form {
            display: inline-block;
        }
    }

    .list-paging-pages {
        display: inline-block;
    }


    .list-paging-icons {
        float: right;
        height: 28px;
    }
    #list-paging-add{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-print{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-wizard{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-refresh{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-increase{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-serial-number{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
}

.option-group h3 {
    color: @option-group-h3;
    margin: 0;
    padding: 0px 15px 10px 0px;
    text-transform: uppercase;
}

.pair-margin-bottom-1{
    margin-bottom: @form-items-height+4 !important;
}
.pair-margin-bottom-2{
    margin-bottom: 2*(@form-items-height+4) !important;
}
.pair-margin-bottom-3{
    margin-bottom: 3*(@form-items-height+4) !important;
}
.pair-margin-bottom-4{
    margin-bottom: 4*(@form-items-height+4) !important;
}
.pair-margin-bottom-5{
    margin-bottom: 5*(@form-items-height+4) !important;
}

.pair-margin-top-1{
    margin-top: @form-items-height+4 !important;
}
.pair-margin-top-2{
    margin-top: 2*(@form-items-height+4) !important;
}
.pair-margin-top-3{
    margin-top: 3*(@form-items-height+4) !important;
}
.pair-margin-top-4{
    margin-top: 4*(@form-items-height+4) !important;
}
.pair-margin-top-5{
    margin-top: 5*(@form-items-height+4) !important;
}
.pair-margin-top--1{
    margin-top: -1*(@form-items-height+4) !important;
}
.pair-margin-top--2{
    margin-top: -2*(@form-items-height+4) !important;
}
.pair-margin-top--3{
    margin-top: -3*(@form-items-height+4) !important;
}
.pair-margin-top--4{
    margin-top: -4*(@form-items-height+4) !important;
}
.pair-margin-top--5{
    margin-top: -5*(@form-items-height+4) !important;
}

input.right_1 {
    padding-right: 22px;
}
input.right_2 {
    padding-right: 42px;
}
input.right_3 {
    padding-right: 62px;
}
input.left_1 {
    padding-left: 22px;
}
input.left_2 {
    padding-left: 42px;
}
input.left_3 {
    padding-left: 62px;
}

.input-right_1 {
    border-left: 1px solid @input-right-border; background: @input-right-background;
    position: absolute; cursor: pointer; right: 6px; margin-top: 1px;
    .border-radius-right(3px);
}
.input-right_2 {
    border-left: 1px solid @input-right-border; background: @input-right-background;
    position: absolute; cursor: pointer; right: 25px; margin-top: 1px;
    .border-radius-top-right(2px);
    .border-radius-bottom-right(2px);
}
.input-right_3 {
    border-left: 1px solid @input-right-border; background: @input-right-background;
    position: absolute; cursor: pointer; right: 44px; margin-top: 1px;
    .border-radius-top-right(2px);
    .border-radius-bottom-right(2px);
}
.input-left_1 {
    border-right: 1px solid @input-left-border; background: @input-left-background;
    position: absolute; cursor: pointer; left: 6px; margin-top: 1px;
    .border-radius-right(3px);
}
.input-left_2 {
    border-right: 1px solid @input-left-border; background: @input-left-background;
    position: absolute; cursor: pointer; left: 25px; margin-top: 1px;
    .border-radius-right(3px);
}
.input-left_3 {
    border-right: 1px solid @input-left-border; background: @input-left-background;
    position: absolute; cursor: pointer; left: 44px; margin-top: 1px;
    .border-radius-right(3px);
}
.input-right_1.disabled,
.input-right_2.disabled,
.input-right_3.disabled,
.input-left_1.disabled,
.input-left_2.disabled,
.input-left_3.disabled{
    cursor: auto;
}

.border-bottom {
	border-bottom: 1px solid @border-bottom;
}

.border-top {
	border-top: 1px solid @border-bottom;
}

/*TODO overflow aby pracoval vsude*/
.scrolling-x{
    overflow-x: auto;
    overflow: -moz-scrollbars-horizontal;
}

.scrolling-y{
    overflow-y: auto;
    overflow: -moz-scrollbars-vertical;
}

.submit {
    height: 28px;
    padding-left: 10px;
    padding-right: 10px;
    background-image: url("../images/prechod-tlacitko-prihlasit.png");
    background-position: left top;
    background-repeat: repeat-x;

    &:disabled{
        background-image: url("../images/form_submit_bg_disabled.png");
    }

    &.back, &.previous, &.next, &.up, &.down, &.save, &.demand, &.order, &.send {
        .border-radius(5px);
        border: 1px solid @submit-border;

        &:disabled, &.disabled {
            border: 1px solid @submit-disabled-border;
            &.hover, &:hover {
                box-shadow: none;
                cursor: default;
            }
        }

        &.hover, &:hover {
            .highlight(@submit-border);
            border: 1px solid @submit-border;
        }
    }

    &.back, &.previous {
        background-image: url("../images/ikony_56x56.png");
        .icon(56, 1, 1);
        &:disabled {
            background-image: url("../images/ikony_56x56_disabled.png");
            .icon(56, 1, 1);
        }
    }

    &.next {
        background-image: url("../images/ikony_56x56.png");
        .icon(56, 2, 1);
        &:disabled {
            background-image: url("../images/ikony_56x56_disabled.png");
            .icon(56, 2, 1);
        }
    }

    &.up {
        background-image: url("../images/ikony_56x56.png");
        .icon(56, 3, 1);
        &:disabled {
            background-image: url("../images/ikony_56x56_disabled.png");
            .icon(56, 3, 1);
        }
    }

    &.down {
        background-image: url("../images/ikony_56x56.png");
        .icon(56, 4, 1);
        &:disabled {
            background-image: url("../images/ikony_56x56_disabled.png");
            .icon(56, 4, 1);
        }
    }

    &.save, &.demand, &.order, &.send {
        padding-left: 44px;
        float: right;
        text-align: center;
    }

    &.save, &.send {
        min-width: 130px;
        height: 48px;
    }

    &.demand, &.order {
        min-width: 100px;
        height: 46px;
        line-height: 46px;
    }

    &.save {
        background-image: url("../images/save-24x24.png"), url("../images/prechod-tlacitko.png");
        background-position: 10px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled, &.disabled {
            background-image: url("../images/save-24x24-disabled.png"), url("../images/prechod-tlacitko-disabled.png");
        }
    }

    &.demand {
        background-image: url("../images/poptavka-24x24.png"), url("../images/prechod-tlacitko.png");
        background-position: 10px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled, &.disabled  {
            background-image: url("../images/poptavka-24x24-disabled.png"), url("../images/prechod-tlacitko-disabled.png");
        }
    }

    &.order {
        background-image: url("../images/objednavka-24x24.png"), url("../images/prechod-tlacitko.png");
        background-position: 10px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled, &.disabled  {
            background-image: url("../images/objednavka-24x24-disabled.png"), url("../images/prechod-tlacitko-disabled.png");
        }
    }

    &.new-wizard {
        .trans(@new-wizard-background, 0.5);
        background-image: url("../images/plus-40x40.png");
        background-position: right center;
        background-repeat: no-repeat;
        .border-radius(5px);
        border: 1px solid @summ-new-wizard-border;
        height: 48px;
        padding-right: 45px;
        line-height: 48px;
        cursor: pointer;

        &:hover:not(.disable), &.hover:not(.disable) {
            background-image: url("../images/plus-40x40-over.png");
            background-position: right center;
            background-repeat: no-repeat;
            .highlight(@summ-new-wizard-highlight);
            border: 1px solid @summ-new-wizard-highlight;
        }

        &.disable {
            background-image: url("../images/plus-40x40-disabled.png");
            background-position: right center;
            background-repeat: no-repeat;
            cursor: default;
        }
    }

    &.send {
        background-image: url("../images/obalka-24x24.png"), url("../images/prechod-tlacitko.png");
        background-position: 10px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled, &.disabled  {
            background-image: url("../images/obalka-24x24-disabled.png"), url("../images/prechod-tlacitko-disabled.png");
        }
    }

    &.send_small, &.back_small, &.next_small, &.yes_small, &.no_small {
        height: 20px;
        .border-radius(3px);
    }

    &.send_small {
        padding-left: 28px;
        background-image: url("../images/form_submit_send.png"), url("../images/form_submit_bg.png");
        background-position: 7px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled  {
            background-image: url("../images/form_submit_send_disabled.png"), url("../images/form_submit_bg_disabled.png");
        }
    }

    &.back_small {
        padding-left: 28px;
        background-image: url("../images/form_submit_back.png"), url("../images/form_submit_bg.png");
        background-position: 7px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled  {
            background-image: url("../images/form_submit_back_disabled.png"), url("../images/form_submit_bg_disabled.png");
        }
    }

    &.next_small {
        padding-left: 28px;
        background-image: url("../images/form_submit_next.png"), url("../images/form_submit_bg.png");
        background-position: 7px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled  {
            background-image: url("../images/form_submit_next_disabled.png"), url("../images/form_submit_bg_disabled.png");
        }
    }

	&.yes_small, &.no_small {
        padding-left: 20px;
		padding-right: 20px;
        background-image: url("../images/form_submit_bg.png");
        background-position: left top;
        background-repeat: repeat-x;
        &:disabled  {
            background-image: url("../images/form_submit_bg_disabled.png");
        }
    }
}

input, div.input {
    padding: 0 5px 0 3px;

    &.input_control_number {
        text-align: right;
    }

    &.centered{
        text-align: center;
    }

    &.align-right{
        text-align: right;
    }
}

.unit {
    position: absolute;
    right: 6px;
    height: 20px;
    line-height: 18px;
    padding: 0 3px;
    border: 1px solid #87B1C0;
    //background: url("../images/input_bg.png") repeat-x;
    //background: @input-right-background;
    .border-radius-right(3px);
    border-left: 1px solid @form-input-shadow-inset;
    color: @form-text-color;
}

//form {
    .step_menu {
        text-align: center;
        padding-bottom: 30px;
        height: 60px;
        position: relative;

        .card_title, .card_subtitle {
            //margin: 0 60px;
            margin: 0 auto;
            width: 700px;
            font-style: italic;
        }

        .card_title {
            font-size: 26px;
            font-family: helvetica;
            color: @step-menu-title-color;
        }

        .card_subtitle {
            font-size: 12px;
            font-family: verdana;
            color: @step-menu-subtitle-color;
        }

        .card-help {
			position: absolute;
            right: -41px;
            top: 60px;
			width: 40px;
			height: 40px;

			.card-help-btn {
				cursor: pointer;
				.shadow(0px, 0px, 12px, 8px, #DEF0FD, inset);
				background: white;
				border: 1px solid #87B1C0;
				border-left: 0;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				padding: 10px 5px;
				position: fixed;
			}
        }

		.button {
			position: absolute;
			top: 0;
			z-index: 10;

			&.button_previous {
				left: 0;
			}
			&.button_next {
				right: 0;
				&.fixed {
					right: 66px;
				}
			}

			&.fixed {
				.previous, .next {
					position: fixed;
					top: 0;
				}

				.label_step {
					display: none;
				}
			}

			.back, .next, .previous {
				.border-radius(5px);
				margin: 4px 4px 0px 4px;
				border: 1px solid @step-menu-border;
				cursor: pointer;

				&:hover {
					.highlight(@step-menu-submit-highlight);
					 border: 1px solid @step-menu-border;
				}

				&:disabled, &.disabled {
					border: 1px solid @step-menu-border-disabled;
					.shadow(0px, 0px, 1px, 0px, grey, inset);
					cursor: default;
				}

                &.loading {
                    background: #FAFCF0 url("../images/button_loading.gif") no-repeat center;
                }
			}

			.back, .previous {
				background: #FAFCF0 url("../images/ikony_56x56.png") no-repeat center;
				.icon(56, 1, 1);
				&:disabled, &.disabled {
					background: #FAFCF0 url("../images/ikony_56x56_disabled.png") no-repeat center;
					.icon(56, 1, 1);
				}
			}

			.label_step {
				margin: 15px 0;
				&:disabled, &.disabled {
					color: @step-menu-color-disabled;
				}
				&.middle  {
					line-height: 56px;
					margin: 4px 0;
				}
			}

			.next {
				background: #FAFCF0 url("../images/ikony_56x56.png") no-repeat center;
				.icon(56, 2, 1);
				&:disabled, &.disabled {
					background: #FAFCF0 url("../images/ikony_56x56_disabled.png") no-repeat center;
					.icon(56, 2, 1);
					border: 1px solid @step-menu-border-disabled;
				}
			}
		}
    }
//}

.no-image{
    background: #e5e5e5 url("../images/no-image-120x90-3.png") no-repeat center center;
}

.price-box {
    width: 960px;
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    z-index: 10;

    .approximate-price {
        bottom: 0;
        left: 0;
        padding: 10px;
        position: absolute;

        .trans(@form-background,0.6);
        border: 1px solid @form-border;
        .border-radius(5px);

        color: @form-text-color;
        font-family: verdana;
        font-size: 12px;

        .icon-info {
            background-image: url("../images/info.png");
        }

        .icon-help {
            background-image: url("../images/help.png");
        }

        .icon-info, .icon-help {
            cursor: pointer;
            background-position: center center;
            background-repeat: no-repeat;
            height: 22px;
            width: 22px;
            margin-left: 10px;
        }

        h3 {
            font-family: helvetica;
            font-style: italic;
            margin: 0;
            color: @approximate-price-h3-color;
        }

        .price {
            line-height: 26px;
            height: 26px;
            text-align: center;

            &.loading {
                background-image: url("../images/price_loading.gif");
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }
}