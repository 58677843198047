/**
 * Základní styly aplikace
 * @author Jan Preuss
 */

body {
	font: 16px/1.5 "Trebuchet MS", "Geneva CE", lucida, sans-serif;
	color: #333;
	background-color: #fff;
}

h1 {
    color: @h1-color;
    font-family: helvetica;
    font-size: 26px;
    font-style: italic;
    font-weight: normal;
    margin: 0;
    padding: 10px 0 0 0;
}

p {
    text-align: justify;
}

table {
    border-spacing: 0px;
    border-collapse: collapse;
}

#ajax-spinner {
	margin: 15px 0 0 15px;
	padding: 13px;
	background: white url('../images/spinner.gif') no-repeat 50% 50%;
	font-size: 0;
	z-index: 123456;
	display: none;
}

div.flash {
	color: @flash-color;
	background: @flash-background;
	border: 1px solid @flash-border;
	padding: 1em;
	margin: 1em 0;
}

a[href^="error:"] {
	background: red;
	color: white;
}

.identity-container {
    text-align: right;
}

.menu-container {
    float: left;
}


