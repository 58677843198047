.contract-info {
    .contract {
        font-size: 14px;
        padding-bottom: 20px;
		position: relative;
		
		&.dialog {
			padding-bottom: 0;
		}
        
        h3 {
            margin: 0;
            padding: 0;
        }
        .registration {
            cursor: pointer;
        }
        
        .cell {
            margin-bottom: 20px;
			
			&.odd {
				.trans(#e8e8e8,0.9);
			}
        }
        
        .submit {
            height: 18px;
            line-height: 18px;
            margin-left: 10px;
            background-image: url("../images/form_submit_bg.png");
            background-position: left top;
            background-repeat: repeat-x;
            border: 1px solid #0f85d6;
            .border-radius(3px);
			text-align: center;
    
            &:hover {
                .highlight(@form-items-highlight);
            }
            
            &.disabled {
                background-image: url("../images/form_submit_bg_disabled.png");
                border: 1px solid #7e929e;
            }
        }
		
		form {
			input {
				&.submit {
					margin: 0;
					color: black;
					font-size: 14px;
					height: 20px;
				}
			}
		}
        
        .border-bottom {
            border-bottom: 1px solid black;
        }
		
		.border-top {
            border-top: 1px solid black;
        }
    }
    .send_small, .back_small, .next_small, .yes_small, .no_small {
        height: 20px;
        .border-radius(3px);
    }
    .groupButtons {
        background: url("../images/formular_tlacitka.png") repeat-x scroll center center rgba(0, 0, 0, 0);
        border: 0 none;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        margin: 18px auto 0;
        padding: 5px;
        text-align: center;
        width: auto;
    }
    
    .card-help-btn {
        background: white;
        border: 1px solid #87B1C0;
        border-radius: 5px;
        padding: 10px 5px;
        position: absolute;
        right: -60px;
        top: 100px;
        .shadow(0px, 0px, 12px, 8px, #DEF0FD, inset);
        cursor: pointer;
    }
}

.dialog-log {
    .dataGrid {
        font-size: 11px;
    }
}

.dialog-confirm-order {
	a {
		text-decoration: underline;
	}
}